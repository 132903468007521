.pagenotfound-container {
  position: relative;
}


#business-card{
  section {
    padding: 24px 0 !important; 
  }
  .business-card{
    .contact-info-container {
      // border: 1px solid red;
      width: 100%;
      height: 100%;
      .header {
        // border: 1px solid red;
        height: 200px;
        width: 100%;
        // overflow: hidden; /* Hide any overflow */
        border-radius: 20px;
        position: relative;
        .cover {
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          overflow: hidden; /* Hide overflow if needed */
          border-radius: 20px;

          // img {
          //   width: 100%; /* Cover the container's width */
          //   height: 100%; /* Cover the container's height */
          //   object-fit: cover; /* Ensure the image covers the container */
          //   position: absolute;
          //   top: 0;
          //   left: 50%;
          //   border-radius: 20px;
          //   -webkit-transform: translateX(-50%) translateY(0%);
          //   -moz-transform: translateX(-50%) translateY(0%);
          //   transform: translateX(-50%) translateY(0%);
          // }
        }
        .cover-quicklink {
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          overflow: hidden; /* Hide overflow if needed */
          border-radius: 20px;

          // img {
          //   width: 100%; /* Cover the container's width */
          //   height: 100%; /* Cover the container's height */
          //   object-fit: cover; /* Ensure the image covers the container */
          //   position: absolute;
          //   top: 0;
          //   left: 50%;
          //   border-radius: 20px;
          //   -webkit-transform: translateX(-50%) translateY(0%);
          //   -moz-transform: translateX(-50%) translateY(0%);
          //   transform: translateX(-50%) translateY(0%);
          // }
          .cover-bg {
            width: 100%; /* Cover the container's width */
            height: 100%; /* Cover the container's height */
            object-fit: cover; /* Ensure the image covers the container */
            position: absolute;
            border-radius: 20px;
            // background-color: red;
            overflow: hidden;
            transition: all 300ms linear;
          }
          svg {
            // border: 1px solid green;
            position: absolute;
            top: 45px;
            left: 50%;
            border-radius: 20px;
            -webkit-transform: translateX(-50%) translateY(0%);
            -moz-transform: translateX(-50%) translateY(0%);
            transform: translateX(-50%) translateY(0%);
            path{
              transition: all 300ms linear;
            }
          }
          .cover-text-home {
            top: calc(50% - 20px);
          }

        }
        .dp {
          // border: 1px solid red;
          height: 100%;
          width: 100%;
          position: relative;
  
          img {
            object-fit: cover; /* Adjust this value as needed */
            max-width: 100%;
            max-height: 100%; /* Ensure the image doesn't exceed container's height */
            height: 150px;
            width: 150px;
            // border: 1px solid red;
            left: 50%;
            top: 35px;
            position: absolute;
            -webkit-transform: translateX(-50%) translateY(50%);
            -moz-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%);
            border-radius: 50%;
            // border: 5px solid red;
            transition: all 300ms linear;
  
          }
        }
        
      }
      
      .content-home {
        margin-top: 20px;
        // border: 1px solid transparent;
        // border: 1px solid red;
        // height: 500px;
        z-index: 1000;

        .links {
          margin-top: 20px;
          position: relative;
          .type {
            font-size: 20px;
            font-weight: 700;
            line-height: 40px;
            // margin-bottom: 10px;
          }
          a {
            text-decoration: none;
            span {
              transition: all 700ms linear;
            }
          }
          .each-link {
            margin-bottom: 10px;
            .more-actions {
              // border: 1px solid red;
              margin-top: 20px;
              strong {
                transition: all 700ms linear;
              }
            }
          }
          .qr-container {
            padding: 20px 20px !important;
          }
          .website {
            // border: 1px solid red;
            padding: 15px 20px;
            transition: all 300ms linear;
            border-radius: 10px;
            .site-link {
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              .material-symbols-outlined {
                margin-right: 0;
              }
              svg {
                path {
                  transition: all 700ms linear;
                }
              }
              .about {
                margin-right: 0;
              }
            }
            .sub {
              padding: 15px 20px;
              // border: 1px solid green;
              border-radius: 10px;
              margin-top: 15px;
              transition: all 300ms linear;
            
            }
            .hr-home {
              width: 100%;
              // border-top: 0.5px solid red !important;
              margin-top: 15px;
              transition: all 300ms linear;
            }
            .home-about {
              flex-direction: column;
              a {
                width: 100%;
              }
              .learn {
                height: 60px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                margin-top: 20px;
                transition: all 300ms linear;
                span {
                  font-size: 20px;
                  font-weight: 700;
                  margin-right: 0;
                }
              }
            }
            
            .scan-qr {
              .qr-code {
                // border: 1px solid red;
                width: 100%;
                height: 100%;
              }
              svg {
                path {
                  // fill: red;
                }
              }
            }
          }
          .update {
            margin-bottom: 5px;
          }
          .deactivate {
            background-color: #D32934 !important;
            margin-bottom: 5px;
            span {
              color: #F5F5F5 !important;
            }
          }
        }
      }
    }
  }
}
.qr-code-container {
  position: relative;
  width: 100%;
  height: 100%;
  // border: 1px solid red;
}
.qr-code-bank {
  width: 100%;
  max-height: 500px;
  border-radius: 20px;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  margin: 0;
  color: #363636 !important;
  cursor: pointer;
}

.blue-check {
  path {
    fill: #179CF0;
  }
}