#about {
  .about {
    padding-bottom: 30px;
    // font-family: 'Montserrat';

    .header {
      .wrapper {
        // border: 1px solid red;
        padding: 0;
        // margin-top: 60px;
      }
      .banner {
        position: relative;
        .hand {
          width: 100%;
        }
        .subt {
          font-family: 'Montserrat';
          position: absolute;
          left: 0;
          width: 100%;
          text-align: center;
          // color: red;
          z-index: 10;
          font-size: 20px;
          margin-right: 0;
          margin: 0 auto;
          // right: 50%;
          top: 11%;
          // transform: translate(50%,-50%);
        }
        strong {
          transition: all 700ms linear;
        }
      }

      // .slide-to-activate-container {
      //   border: 1px solid red;
      //   padding: 0 24px;
      //   max-width: var(--max-width);
      //   margin: 0 auto; 
      // }

      // .top-activate-container {
      //   padding: 0 24px;
      //   max-width: var(--max-width);
      //   margin: 0 auto; 
      // }
      // .top-activate {
      //   text-decoration: none;
      //   font-family: "Roboto";
      // }
      // .helps {
      //   // border: 1px solid red;
      //   text-decoration: none;
      //   width: 100%;
      //   margin-top: 50px;
      //   height: 60px;
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   border-radius: 10px;
      //   transition: all 300ms linear;
      //   text-decoration: none;
      //   background-color: #179CF0;
      //   display: flex;
      //   justify-content: space-between;
      //   span {
      //     font-size: 20px;
      //     font-weight: 700;
      //     transition: all 300ms linear;
      //     margin-right: 0;
      //     text-decoration: none;
      //     color: #F5F5F5;
      //   }
      //   .activate-blue {
      //     margin-left: 20px;
      //   }
      //   .arrow_circle_right {
      //     font-size: 28px;
      //     margin-right: 20px;
      //   }
      // }

      .top-activate-container {
        padding: 0 24px;
        max-width: var(--max-width);
        margin: 0 auto; 
      }
      .top-activate {
        text-decoration: none;
        font-family: "Roboto";
      }
      .helps {
        // border: 1px solid red;
        text-decoration: none;
        width: 100%;
        margin-top: 50px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        transition: all 300ms linear;
        text-decoration: none;
        // background-color: #179CF0;
        display: flex;
        justify-content: flex-end;
        position: relative;
        overflow: hidden;
        .slider {
          position: absolute;
          height: 50px;
          width: 100px;
          // background-color: red;
          margin: 5px;
          border-radius: 7px;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 300ms linear;
          overflow: hidden;

          .blocker {
            height: 50px;
            width: 200px;
            background-color: red;
          }
        }
        .slide-to-activate {
          justify-self: flex-end;
          font-size: 16px;
          margin-right: 20px;
          font-weight: 400;
          font-family: "Roboto";
          // color: blue;
        }
        span {
          font-size: 20px;
          font-weight: 700;
          transition: all 300ms linear;
          margin-right: 0;
          text-decoration: none;
          // color: #F5F5F5;
        }
        .activate-blue {
          margin-left: 20px;
        }
        .arrow_circle_right {
          font-size: 35px;
          margin-left: 0;
          // margin-right: 20px;
        }
      }
    }
    .wrapper {
      // border: 1px solid red;
      .about-body {

        
        .content-body {
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .about-title {
            font-size: 20px;
            font-weight: 700;
          }
          .about-content {
            font-size: 16px;
            margin-top: 10px;
            margin-right: 0;
            text-align: justify;
          }
          .step1 {
            margin-top: 40px;
            margin-bottom: 20px;
            width: 195px;
            align-self: center;
          }
          .step2 {
            margin-top: 40px;
            margin-bottom: 20px;
            width: 330px;
            align-self: center;
          }
        }
        
      }
    }
    .features {
      margin-top: 20px;
      padding: 40px 0;
      transition: all 300ms linear;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .top {
        font-size: 20px;
        font-weight: 700;
      }
      .bot {
        .pair-container {
          // border: 1px solid blue;
          margin-top: 20px;
          // width: 300px;
        }
        .pair {
          // border: 1px solid red;
          display: flex;
          align-items: center;
          flex-direction: row;
          padding: 5px 0;
          .logo {
            width: 80px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              path {
                transition: all 700ms linear;
              }
            }
          }

        }
      }
    }
    .about-body {
      span {
        // margin-right: 0;
      }
      .not-available {
        background-color: #D32934;
        color: #F5F5F5;
        padding: 5px 10px;
        border-radius: 10px;
        margin-top: 10px;
        span {
          margin-right: 0;
          font-size: 13px;
          // font-weight: 700;
        }
      }
      .product-container {
        // border: 1px solid red;
        margin-top: 40px;
        .product-3d {
          aspect-ratio: 1 / 1;
          // border: 1px solid green;
          transition: all 300ms linear;
          border-radius: 20px;
          overflow: hidden;
        }
        .product-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          .product-price {
            margin-top: 10px;
            margin-right: 0;
            .amount {
              font-size: 40px;
              font-weight: 700;
            }
            .currency {
              font-weight: 700;
              margin-right: 0;
            }
          }
          .product-type {
            font-size: 20px;
            margin-right: 0;
            // font-weight: 700;
          }
        }
      }
    }
  }
  .quicklink-title {
    margin-top: 40px;
    font-size: 50px;
    font-weight: 700;
  }
  .terms-content {
    // border: 1px solid red;
    width: 100%;
    margin-top: 30px;
    .terms-title {
      font-size: 30px;
      font-weight: 700;
      // margin-bottom: 5px;
    }
    .terms-info {
      // text-align: justify;
      // color: red;
      display: flex;
      flex-direction: column;
    }
    strong {
      transition: all 300ms linear;
    }
  }
}

ul {
  margin: 0;
  padding-left: 20px;
  li {
    margin-bottom: 10px;
  }
}
.margin-bot {
  height: 30px;
}