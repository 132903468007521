#privacy {
  .quicklink-title {
    margin-top: 40px;
    font-size: 50px;
    font-weight: 700;
  }
  .privacy-content {
    // border: 1px solid red;
    width: 100%;
    margin-top: 30px;
    .privacy-title {
      font-size: 30px;
      font-weight: 700;
      // margin-bottom: 5px;
    }
    .privacy-info {
      // text-align: justify;
      // color: red;
    }
    strong {
      transition: all 300ms linear;
    }
  }
}

ul {
  margin: 0;
  padding-left: 20px;
  li {
    margin-bottom: 10px;
  }
}
.margin-bot {
  height: 30px;
}