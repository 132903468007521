:root {
  --main-color: #009CFF;
  --main-bg: #F5F5F5;
  --main-text: #363636;
  --gradient-text: linear-gradient(to right, #0072ff , #00c6ff);
  --gradient-45-deg: linear-gradient(45deg, #0072ff , #00c6ff);
  --gradient-new: linear-gradient(to right, #68B7DA, #6894D9, #6771D9, #7F67D9, #A168D8, #C667D9);
  --subtitle-dark: #939ba1;
  --subtitle: #61676c;
  --max-width: 500px;
  --max-width-docs: 600px;
  --footer-adjustment: calc(100vh - 298px);
  --footer-adjustment-404: calc(100vh - 195px);

  /* --max-width: 1480px; */

  /* --test1: 1px solid red;
  --test2: 1px solid green;
  --test3: 1px solid yellow;
  --test4: 1px solid purple; */
}

body {
  margin: 0;
  font-family: 'Roboto';
  /* font-weight: 400; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* transition: all 300ms linear !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.loader {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--main-text);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

#pagenotfound {
  height: var(--footer-adjustment-404);
  /* transition: all 300ms linear; */
}
.pagenotfound {
  height: 100%;
}
.pagenotfound-title {
  font-size: 30px;
  font-weight: 700;
  margin-top: 50px;
  margin-right: 0;
}
.pagenotfound .quicklink-logo {
  margin: 0 10px -5px 10px;
  transition: all 300ms linear;
}
/* @media screen and (max-width: 456px) {
  .pagenotfound .quicklink-logo {
    margin: 0 10px -5px 0;
  }
} */
.pagenotfound .quicklink-logo path {
  transition: all 1s linear;
}
.pagenotfound .customer-service{
  margin-top: 10px;
}
/* .pagenotfound .smartap-name {
  position: relative;
} */
/* .pagenotfound .smartap-name .tap {
  position: absolute;
  margin-left: -10px;
}
.pagenotfound .smartap-name .smar {
  font-weight: 200;
} */

.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
.h-center {
  align-items: center;
}
.v-center {
  justify-content: center;
}

section {
  padding: 30px 0px;
  border: var(--test1);
  transition: all 300ms linear;
  position: relative;
  z-index: 10 !important;
}

.section-top {
  margin-top: 30px;
}
@media screen and (max-width: 480px) {
  .section-top {
    margin-top: 60px;
  }
}
.section-top-websites {
  margin-top: 30px;
}
@media screen and (max-width: 480px) {
  .section-top-websites {
    margin-top: 30px;
  }
}
.section-bottom {
  margin-bottom: 30px;
}

.section-footer {
  padding: 60px 0 !important;
}

section .title {
  /* font-family: 'Roboto'; */
  font-size: 80px;
  font-weight: 700;
  /* margin: 10px 0; */
  background-image: var(--gradient-text);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}
@media screen and (max-width: 480px) {
  section .title {
    font-size: 50px;
  }
}
section .notfound {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0;
  color: var(--subtitle);
  transition: all 300ms linear;
}
section .subtitle {
  font-size: 20px;
  font-weight: 400;
  margin: 5px 0;
  color: var(--main-color);
  /* transition: all 300ms linear !important; */
  width: 100px;
  /* border: 1px solid red; */
}

section .subtitle svg {
  transition: all 300ms linear;
  /* fill: red; */
}
section .subtitle span {
  transition: all 300ms linear;
}
section .subtitle:hover span {
  /* cursor: pointer; */
  color: var(--main-color);
  transition: all 300ms linear;
}
section .subtitle:hover {
  cursor: pointer;
}
section .subtitle svg path {
  fill: var(--main-color) !important;
}
section .subtitle:hover svg path {
  fill: var(--main-color) !important;
  transition: all 300ms linear;
}
section .subtitle:hover svg {
  transform: translate(3px, 0);
  transition: all 300ms linear;
  /* fill: red; */
}
section .subtitle path,span {
  /* fill: var(--subtitle); */
  margin-right: 10px;
  /* transition: all 300ms linear; */
}
section .subtitle path {
  transition: all 300ms linear;
}
section .highlight {
  font-size: 30px;
  font-weight: 700;
  margin-right: 0;
  margin-bottom: 5px;
}
.section-info {
  line-height: 25px;
  margin-right: 0;
}

.goback {
  padding-left: 5px;
}
section .goback .back {
  transition: all 300ms linear;
  transform: scaleX(-1) !important;
  margin-right: 10px;
}
section .goback:hover span {
  /* cursor: pointer; */
  color: var(--main-color);
  transition: all 300ms linear;
}
section .goback:hover {
  cursor: pointer;
}
section .goback:hover svg path {
  fill: var(--main-color);
  transition: all 300ms linear;
}
section .goback:hover .back {
  transform: translate(-3px, 0) scaleX(-1)  !important;
  transition: all 300ms linear;
}
section .goback path,span {
  fill: var(--subtitle);
}

section .wrapper {
  display: flex;
  flex-direction: row;
  border: var(--test1);
  padding: 0 24px;
  max-width: var(--max-width);
  margin: 0 auto; 
  height: 100%;
}
section .wrapper-ikaw {
  display: flex;
  flex-direction: row;
  border: var(--test1);
  /* padding: 0 24px; */
  max-width: var(--max-width);
  margin: 0 auto; 
  height: 100%;
}
section .wrapper-docs {
  display: flex;
  flex-direction: row;
  border: var(--test1);
  padding: 0 30px;
  max-width: var(--max-width-docs);
  margin: 0 auto; 
  height: 100%;
}

section .card-wrapper {
  border: var(--test1);
  padding: 0 24px;
  max-width: var(--max-width);
  margin: 0 auto; 
}

section .pagetopMargin {
  height: 20px;
  width: 100%;
  /* background-color: red; */
}
@media screen and (max-width: 480px) {
  section .pagetopMargin {
    display: none;
  }
}

/* nav .wrapper {
  max-width: 1480px;
  margin: 0 auto;
  border: var(--test1);
} */

.btn {
  background-color: var(--main-bg);
  color: var(--main-color);
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  /* font-family: "Roboto"; */
  font-size: 16px;
  border-radius: 20px;
  padding: 5px 15px;
  margin: 0 10px 0 10px;
  box-shadow: -1px -2px 1px rgba(255, 255, 255, 1),
              1px 1px 3px rgba(0, 0, 0, 0.15);
}
.btn:hover {
  box-shadow: inset -1px -2px 1px rgba(255, 255, 255, 1),
              inset 1px 1px 3px rgba(0, 0, 0, 0.15);
}
.btn:hover span {
  display: inline-block;
  transform: scale(0.98);
}

.gradient-text {
  background-image: linear-gradient(45deg, #0379C3 , #63C1FC);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

.menu-panel-none {
  visibility: hidden !important;
}


.card-container {
  margin: 30px 0 0 0;
  position: relative;
  height: 100%;
  width: 100%;
  /* border: 1px solid yellow; */
}
.card-container .card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 880px) {
  .card-container .card {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 480px) {
  .card-container .card {
    grid-template-columns: repeat(1, 1fr);
  }
}
.card-container .card .each-card {
  position: relative;
  overflow: hidden;
  height: 400px;
  border-radius: 30px;
  transition: all 300ms linear;
}
.card-container .card .each-card:hover .thumbnail {
  transform: scale(1.2);
}
.card-container .card .thumbnail {
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 300ms linear;
}
.card-container .card .info {
  /* background: red; */
  color: white;
  position: absolute;
  z-index: 2;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  padding: 24px;
  box-shadow: none !important;
  /* background-image: linear-gradient(to top, rgba(255,0,0,0), rgb(0, 0, 0)); */
}
.card-container .card .info .info-type {
  font-size: 20px;
}
.card-container .card .info .info-title {
  font-size: 30px;
  font-weight: 700;
  max-width: 330px;
  /* border: 1px solid green; */
}
@media screen and (max-width: 480px) {
  .card-container .card .info .info-type {
    font-size: 16px;
  }
  .card-container .card .info .info-title {
    font-size: 20px;
  }
}

/* HOME card overlay */
.card-container .card .overlay {
  position: absolute;
  /* border: 1px soid red; */
  /* background-color: #F5F5F5; */
  border-radius: 30px;
  height: 100%;
  width: 100%;
  z-index: 3;
  box-shadow: none !important;
  -webkit-transition: transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transform: translateY(100%);
}
.card-container .card .each-card:hover .overlay {
  transform: translateY(235px);
}
.card-container .card .overlay .button-container{
  box-shadow: none !important;
  /* border: 1px solid red; */
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.card-container .card .overlay .button-container .subtype{
  /* border: 1px solid red; */
  padding-bottom: 20px;
  margin-left: 10px;
  font-size: 30px;
  font-weight: 700;
  background-image: var(--gradient-text);
  background-size: 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}
.card-container .card .overlay .button-container .card-button {
  background-color: var(--main-bg);
  color: var(--main-color);
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  /* font-family: "Roboto"; */
  font-size: 16px;
  border-radius: 20px;
  padding: 10px 35px;
  margin: 0 10px 0 10px;
  transition: all 300ms linear;
  margin-bottom: 10px;
  /* width: 200px; */
  /* max-width: 200px; */
  /* box-shadow: -1px -2px 1px rgba(255, 255, 255, 1),
               1px 1px 3px rgba(0, 0, 0, 0.15); */
}

.seeMore-container {
  padding: 20px;
  border-radius: 30px;
  transition: all 300ms linear;
  /* height: 400px; */
}
@media screen and (max-width: 480px) {
  .seeMore-container {
    height: 360px;
  }
}
.seeMore-container:hover .thumbnail{
  transform: scale(1.2);
}
.grid-seeMore {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  height: 100%;
  width: 100%;
}
.seeMore-each {
  /* border: 1px solid red; */
  border-radius: 20px;
  position: relative;
  overflow: hidden;

}
.seeMore-each .thumbnail{
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: all 300ms linear;
  border-radius: 20px;
}
.q4 {
  border-radius: 20px;
  transition: all 300ms linear;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* transition: all 300ms linear; */
  cursor: pointer;
  position: relative;
}
.q4 span {
  font-size: 20px;
  font-weight: 400;
  color: var(--subtitle);
  transition: all 300ms linear;
  z-index: 5;
}
.q4 svg path {
  fill: var(--subtitle);
}
.q4 svg {
  transition: all 300ms linear;
}
.q4:hover svg {
  transform: translate(3px, 0);
  transition: all 300ms linear;
}
.q4:hover svg path {
  /* fill: white; */
  transition: all 300ms linear;
}
.q4:hover span {
  /* color: white; */
  transition: all 300ms linear;
}
.q4:hover .cover {
  opacity: 1;
}
.q4 .cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--gradient-45-deg);
  opacity: 0;
  transition: all 300ms linear;
}


/* AUGMENTED REALITY */
model-viewer {
  overflow-x: hidden;
  /* border: 1px solid red; */
  width: 100%;
  height: 100%;
}

/* override icons thickness */
.material-symbols-outlined {
  margin-left: 10px;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.custom-tooltip {
  z-index: 6;
}

.modal-container {
  position: relative;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  border-radius: 10px !important;
}