.pagenotfound-container {
  position: relative;
}

#pagenotfound {
  .section-pagenotfound {
    height: var(--footer-adjustment-404);

    .top-activate-container {
      // padding: 0 24px;
      max-width: var(--max-width);
      margin: 0 auto; 
      opacity: 0;
    }
    .top-activate {
      text-decoration: none;
      font-family: "Roboto";
    }
    .helps {
      // border: 1px solid red;
      text-decoration: none;
      width: 100%;
      margin-top: 50px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      transition: all 300ms linear;
      text-decoration: none;
      // background-color: #179CF0 !important;
      display: flex;
      justify-content: flex-end;
      position: relative;
      overflow: hidden;
      z-index: 20;
      .slider {
        position: absolute;
        height: 50px;
        width: 100px;
        // background-color: red;
        margin: 5px;
        border-radius: 7px;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 300ms linear;
        overflow: hidden;

        .blocker {
          height: 50px;
          width: 200px;
          background-color: red;
        }
      }
      .slide-to-activate {
        justify-self: flex-end;
        font-size: 16px;
        margin-right: 20px;
        font-weight: 400;
        // color: blue;
      }
      span {
        font-size: 20px;
        font-weight: 700;
        transition: all 300ms linear;
        margin-right: 0;
        text-decoration: none;
        // color: #F5F5F5;
      }
      .activate-blue {
        margin-left: 20px;
      }
      .arrow_circle_right {
        font-size: 35px;
        margin-left: 0;
        // margin-right: 20px;
      }
    }
    .help-me-label {
      margin-bottom: 20px;
    }
    .help-me {
      position: relative;
      z-index: 20;
      // border: 1px solid red;
      border-radius: 10px;
      cursor: pointer;
      padding: 15px 20px;
      margin-bottom: 10px;
      transition: all 300ms linear;
      .site-link {
        display: flex;
        justify-content: space-between;
        svg {
          path {
            transition: all 300ms linear;
          }
        }
      }
      a {
        text-decoration: none;
        transition: all 300ms linear;
      }
    }

    .wrapper {
      position: relative;
      .pagenotfound {
        margin-top: 50px !important;
        // border: 1px solid yellow;
        position: relative;
        .customer-service{
          margin-top: 10px;
          position: absolute;
        }
        .help {
          // border: 1px solid red;
          text-decoration: none;
          width: 100%;
          margin-top: 50px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          transition: all 300ms linear;
          position: absolute;
          z-index: 20;
          opacity: 0;
          span {
            font-size: 20px;
            font-weight: 700;
            transition: all 300ms linear;
            margin-right: 0;
          }
        }
      }
      .pagenotfound-loader {
        // background-color: red !important;
        opacity: 1;
        position: absolute;
        margin-top: 50px;
        height: 100%;
        width: calc(100% - 50px);
        .loader-container {
          width: calc(100% + 3px);
          height: 80%;
          // background-color: #ffffff;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 300ms linear;
          .lds-ellipsis-all {
            display: inline-block;
            position: relative;
            width: 80px;
            height: 80px;
          }
          .lds-ellipsis-all div {
            position: absolute;
            top: 33px;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            // background: var(--main-text);
            transition: all 300ms linear;
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
          }
          .lds-ellipsis-all div:nth-child(1) {
            left: 8px;
            animation: lds-ellipsis-all1 0.6s infinite;
          }
          .lds-ellipsis-all div:nth-child(2) {
            left: 8px;
            animation: lds-ellipsis-all2 0.6s infinite;
          }
          .lds-ellipsis-all div:nth-child(3) {
            left: 32px;
            animation: lds-ellipsis-all2 0.6s infinite;
          }
          .lds-ellipsis-all div:nth-child(4) {
            left: 56px;
            animation: lds-ellipsis-all3 0.6s infinite;
          }
          @keyframes lds-ellipsis-all1 {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes lds-ellipsis-all3 {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(0);
            }
          }
          @keyframes lds-ellipsis-all2 {
            0% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(24px, 0);
            }
          }
        
        }
      } 
    }
    
  }
}


#business-card{
  section {
    padding: 24px 0 !important; 
  }
  .business-card{
    .contact-info-container {
      // border: 1px solid red;
      width: 100%;
      height: 100%;
      .header {
        // border: 1px solid red;
        height: 200px;
        width: 100%;
        // overflow: hidden; /* Hide any overflow */
        border-radius: 20px;
        position: relative;
        .cover {
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          overflow: hidden; /* Hide overflow if needed */
          border-radius: 20px;

          img {
            width: 100%; /* Cover the container's width */
            height: 100%; /* Cover the container's height */
            object-fit: cover; /* Ensure the image covers the container */
            position: absolute;
            top: 0;
            left: 50%;
            border-radius: 20px;
            -webkit-transform: translateX(-50%) translateY(0%);
            -moz-transform: translateX(-50%) translateY(0%);
            transform: translateX(-50%) translateY(0%);
          }
        }
        .cover-quicklink {
          justify-content: center; /* Center horizontally */
          align-items: center; /* Center vertically */
          overflow: hidden; /* Hide overflow if needed */
          border-radius: 20px;

          img {
            width: 100%; /* Cover the container's width */
            height: 100%; /* Cover the container's height */
            object-fit: cover; /* Ensure the image covers the container */
            position: absolute;
            top: 0;
            left: 50%;
            border-radius: 20px;
            -webkit-transform: translateX(-50%) translateY(0%);
            -moz-transform: translateX(-50%) translateY(0%);
            transform: translateX(-50%) translateY(0%);
          }
          .cover-bg {
            width: 100%; /* Cover the container's width */
            height: 100%; /* Cover the container's height */
            object-fit: cover; /* Ensure the image covers the container */
            position: absolute;
            border-radius: 20px;
            // background-color: red;
            overflow: hidden;
            transition: all 300ms linear;
          }
          svg {
            position: absolute;
            top: 45px;
            left: 50%;
            border-radius: 20px;
            -webkit-transform: translateX(-50%) translateY(0%);
            -moz-transform: translateX(-50%) translateY(0%);
            transform: translateX(-50%) translateY(0%);
            path{
              transition: all 300ms linear;
            }
          }

        }
        .dp {
          // border: 1px solid red;
          height: 100%;
          width: 100%;
          position: relative;
  
          img {
            object-fit: cover; /* Adjust this value as needed */
            max-width: 100%;
            max-height: 100%; /* Ensure the image doesn't exceed container's height */
            height: 150px;
            width: 150px;
            // border: 1px solid red;
            left: 50%;
            top: 35px;
            position: absolute;
            -webkit-transform: translateX(-50%) translateY(50%);
            -moz-transform: translateX(-50%) translateY(50%);
            transform: translateX(-50%) translateY(50%);
            border-radius: 50%;
            border: 5px solid red;
            transition: all 300ms linear;
  
          }
        }
        
      }
      
      .content-call {
        margin-top: 75px;
        // border: 1px solid transparent;
        // border: 1px solid red;
        // height: 500px;
        z-index: 1000;
        // visibility: hidden;

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .name-container {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              margin-left: 10px;
              path {
                transition: all 700ms linear;
                fill: #179CF0;
              }
            }
          }
          span {
            margin-right: 0;
          }
          .subscription {
            font-size: 20px;
            margin-top: 15px;
            text-align: center;
            // border: 1px solid red;
          }
          .ikaw-name {
            font-size: 30px;
            font-weight: 700;
            // margin-top: 15px;
            text-align: center;
          }
          .subs-num {
            text-align: center;
            margin-top: 5px;
          }
        }

        .cta {
          // border: 1px solid red;
          display: flex;
          gap: 10px;
        }

        .pro-cta {
          // border: 1px solid blue;
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          gap: 10px;
          .pro-button {
            background-color: #dfdfdf;
            padding: 15px 0;
            border-radius: 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 300ms linear;
            cursor: pointer;
            span {
              margin-right: 0;
              font-size: 20px;
              font-weight: 700;
            }
            svg {
              margin-right: 20px;
              path {
                transition: all 700ms linear;
              }
            }
          }
          .account-settings {
            margin-top: 10px;
          }
        }

        .edit-contact-link {
          text-decoration: none;
          transition: all 300ms linear;
          cursor: pointer;
          .edit-contact {
            padding: 15px;
            border-radius: 10px;
            margin-top: 20px;
            transition: all 300ms linear;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-weight: 700;
              font-size: 20px;
              margin-right: 0;
            }
            .edit-contact-icon {
              // margin-right: 10px;
              path {
                transition: all 700ms linear;
              }
            }
          }
        }
        .more-options-link {
          text-decoration: none;
          transition: all 300ms linear;
          cursor: pointer;
          .more-options {
            // padding: 15px;
            border-radius: 10px;
            margin-top: 20px;
            transition: all 300ms linear;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60px;
            height: 60px;

            span {
              font-weight: 700;
              font-size: 20px;
              margin-right: 0;
            }
            .more-options-icon {
              // margin-right: 10px;
              path {
                transition: all 700ms linear;
              }
            }
          }
        }
        .add-contact-link {
          text-decoration: none;
          transition: all 300ms linear;
          width: 100%;
          cursor: pointer;
          .add-contact {
            padding: 15px 0;
            border-radius: 10px;
            margin-top: 20px;
            transition: all 300ms linear;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-weight: 700;
              font-size: 20px;
              margin-right: 0;
            }
            .add-contact-icon {
              margin-right: 20px;
              path {
                transition: all 700ms linear;
              }
            }
          }
        }
        .links {
          margin-top: 5px;
          position: relative;
          .type {
            font-size: 20px;
            font-weight: 700;
            line-height: 40px;
            // margin-bottom: 10px;
          }
          a {
            text-decoration: none;
            span {
              transition: all 700ms linear;
            }
          }
          .each-link {
            margin-bottom: 10px;
          }
          .qr-container {
            padding: 20px 20px !important;
          }
          .website {
            // border: 1px solid red;
            padding: 15px 20px;
            transition: all 300ms linear;
            border-radius: 10px;
            .site-link {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .material-symbols-outlined {
                margin-right: 0;
              }
              svg {
                path {
                  transition: all 700ms linear;
                }
              }
              .about {
                margin-right: 0;
              }
            }
            
            .scan-qr {
              .qr-code {
                // border: 1px solid red;
                width: 100%;
                height: 100%;
              }
              svg {
                path {
                  // fill: red;
                }
              }
            }
          }
          .google-map {
            // border: 1px solid red;
            margin-bottom: 10px;
            border-radius: 10px;
            aspect-ratio: 1 / 1;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.menu-qr-code-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.menu-qr-code{
  width: 100%;
  height: 500px;
}

.exchange-form-confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    margin-bottom: 10px;
    path {
      fill: var(--main-bg);
    }
  }
  span {
    color: var(--main-bg);
    margin-right: 0;
    font-size: 20px;
    font-weight: 700;
    // width: 100%;
  }
}
.upgrade-pro{
  // background-color: #ffffff;
  width: 100%;
  border-radius: 20px;
  padding: 20px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  transition: all 300ms linear;
  .upgrade-pro-title {
    // font-size: 20px;
    // font-weight: 700;
    // text-align: center;
    margin-right: 0;
  }
  .pro-features {
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    .pro-features-title{
      margin-right: 0;
      margin-top: 15px;
      font-weight: 700;
    }
    .pricing-title{
      margin-right: 0;
      font-weight: 700;
    }
    .price {
      font-size: 30px;
    }
    ul {
      margin: 10px 0;
      li {
        margin-bottom: 5px;
      }
    }
  }
  .request-upgrade {
    padding: 15px 20px;
    margin-top: 20px;
    width: 100%;
    // border: 1px solid red;
    border-radius: 10px;
    box-sizing: border-box;
    transition: all 300ms linear;
    a {
      text-decoration: none;
    }
    .site-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        transition: all 700ms linear;
      }
      svg {
        path {
          transition: all 700ms linear;
        }
      }
    }
  }
}
.exchange-form-container{
  position: absolute;
  top: 50%; 
  right: 50%;
  transform: translate(50%,-50%);
  max-width: 546px;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  // border: 1px solid red;
  // background-color: aqua;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .exchange-form-close {
    z-index: 10;
    align-self: flex-end;
    margin-bottom: 10px;
    cursor: pointer;
    svg {
      path {
        fill: var(--main-bg);
      }
    }
  }
  .exchange-form {
    // background-color: burlywood;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px 20px;
    .captcha {
      width: 100%;
      margin-bottom: 10px;
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      input, textarea {
        // background-color: #272727;
        // color: red;
        font-size: 16px;
        font-family: "Roboto";
        border-radius: 10px;
        margin-bottom: 15px;
        padding: 15px 20px;
        border: none;
      }
      textarea {
        resize: none;
        height: 4em;
      }
      .send {
        margin-bottom: 0;
        border-radius: 10px;
        padding: 15px 20px;
        border: none;
        font-family: "Roboto";
        font-size: 20px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          margin-left: 20px;
        }
      }
      input:focus, textarea:focus, select:focus {
        outline-offset: 0px !important;
        outline: none !important;
      }
    }
  }
}

.age-sex-container {
  // border: 1px solid red;
  margin-top: 20px;
  display: flex;
  gap: 20px;

  .links {
    width: 100%;
  }
  .each-link {
    margin-bottom: 0 !important;
  }

}

.ako-container {
  // background-color: #71E8FB;
  position: relative;
  height: 70vh;
  width: 100%;
  color: white;
  // border: 1px solid red;

  .ako-holder {
    position: absolute;
    top: -24px;
    left: 0;
    background-color: #2EC5EB;
    height: 830px;
    width: 100%;
  }

  .logo-container {
    // border: 1px solid red;
    padding-left: 24px;
    padding-top: 24px;
    img {
      height: 100px;
    }
  }

  .nick-holder {
    margin: 60px 0;
    margin-left: 24px;
    .nickname {
      font-size: 50px;
      font-weight: 700;
    }
  }
  .ako-info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    font-size: 20px;
    span {
      margin-bottom: 10px;
    }
  }

  .subway-container {
    // border: 1px solid red;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 30px;
    }
  }
  
}
